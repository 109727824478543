<template>
  <div>
    <div class="px-3 py-4 md:p-8">
      <h4 class="text-subhead-3 md:text-h4 mb-1 md:mb-2">Location & infrastructure</h4>
      <p class="text-caption-2 md:text-body text-black-70">{{ item.address }}</p>
    </div>
    <div class="h-[235px] md:h-[580px]">
      <PropertiesMap :properties="[marker]" center-by-marker default-marker :zoom="14" :show-controls="false" />
    </div>
    <UiButtonGhost id="view-map" class="m-3 md:hidden" @click="openMapsApp">
      <UiIcon name="map" />
      Open Map
    </UiButtonGhost>
  </div>
</template>

<script setup lang="ts">
import type { PropertyTypes } from '@/types'
type Props = {
  item: PropertyTypes.PropertyDetailed
}

const props = defineProps<Props>()

const marker = computed(() => {
  return {
    latitude: Number(props.item.latitude),
    longitude: Number(props.item.longitude),
  }
})

const openMapsApp = () => {
  const latitude = marker.value.latitude
  const longitude = marker.value.longitude
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const url = isIOS
    ? `maps://maps.apple.com/?q=${latitude},${longitude}`
    : `https://www.google.com/maps?q=${latitude},${longitude}`
  window.open(url, '_blank')
}
</script>

<style scoped></style>
