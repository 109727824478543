<template>
  <div class="relative w-[312px] cursor-pointer overflow-hidden rounded-xl border border-dm-50">
    <div class="p-2 md:p-4">
      <div class="mb-3 flex gap-2">
        <PropertyTabsUnitTag
          :label="item.rooms_count.en.length ? item.rooms_count.en : PROPERTIES_ROOMS_MAP[item.key]"
        />
        <PropertyTabsUnitTag v-if="item.type.en.length" :label="item.type.en" />
      </div>
      <img :src="item.levels_photos[0].image" alt="plan" class="mx-auto h-44 w-56" />
    </div>
    <div class="bg-black-03 p-2 md:p-4">
      <p class="text-subhead-4 mb-1.5">
        {{ aedFormatter.format(Number(item.price.min)) }} {{ item.price.currency }} -
        {{ aedFormatter.format(Number(item.price.max)) }} {{ item.price.currency }}
      </p>
      <div class="text-body-2 flex items-center gap-2">
        <p>Type {{ index + 1 }}</p>
        •
        <p>{{ item.sale_units_count }} units</p>
        •
        <p>from {{ item.area_min.m2 }} m<sup>2</sup></p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropertyTypes } from '@/types'
import { PROPERTIES_ROOMS_MAP } from '@/constants'

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})
type Props = {
  item: PropertyTypes.Apartment
  index: number
}
defineProps<Props>()
</script>

<style scoped></style>
